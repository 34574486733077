@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap');

/* creates a concave curve <div class="curve" /> */
.concave {
  opacity: .3;
  transform: translate(-49px, 40px) rotate(8deg);
  border-radius: 59%;
  box-shadow: 40px -50px #FFF;
  height: 150px;
  width: 170px;
}

.small-shake {
  animation: small-shake 0.3s linear ;
  transform: translateX(0);
}

@keyframes small-shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-10px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

.fade-up-and-out {
  animation: up-and-out 1s linear ;
  transform: translateY(0);
  opacity: 0;
}

@keyframes up-and-out {
  0% {
    transform: translateY(0);
    opacity: .1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 1;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}


.grow-out {
  animation: grow-out .6s linear 1 normal forwards;
  animation-iteration-count: 1;
  transform: scale(1);
  opacity: 1;
}

@keyframes grow-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(8);
    opacity: 0;
  } 
}

.slam-down {
  animation: slam-down .8s linear 1 normal forwards;
  animation-iteration-count: 1;
  transform: scale(1);
  opacity: 1;
}

@keyframes slam-down {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  80% {
    opacity: 1;
    transform:translateY(-5px);
  }
  90% {
    opacity: 1;
    transform:translateY(5px);
  }
  100% {
    opacity: 1;
    transform:  translateY(0);
  }
}

.flash {
  animation: flash .5s linear 1 normal forwards;
  animation-iteration-count: 1;
  transform: scale(1);
  opacity: 1;
}

@keyframes flash {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.2);
  }
  40% {
    opacity: .5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(.8);
  }
}